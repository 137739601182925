<template>
	<div>
		<vs-prompt
      v-show="!isModalConfirmApprove"
      class="settings-moderation"
      :title="$t('Q & A Settings')"
      @accept="submitData()"
      :accept-text="$t('Save')"
      :is-valid="isFormValid && !isFetching"
      :disabled-outside="true"
      :active.sync="isModalActiveLocal">
      <!-- reply section -->
      <div class="p-4 settings-section relative">
          <div class="mb-6 flex justify-between items-center">
              <div class="font-bold text-lg">
                  <div>{{ $t('Moderation') }}</div>
                  <div class="font-normal text-small text-secondary">{{ $t('Review question before they are go presented to your participants') }}.</div>
              </div>
              <div class=" ml-6"><vs-switch @change="onChangeModeration" @click.stop="" color="success" v-model="dataIsModeration"/></div>
          </div>
          <div class="mb-6 flex justify-between items-center">
              <div class="font-bold text-lg">
                  <div>{{ $t('Disabled Questions') }}</div>
                  <div class="font-normal text-small text-secondary">{{ $t('Prevent participants from sending new question') }}.</div>
              </div>
              <div class=" ml-6"><vs-switch @click.stop="" color="success" v-model="dataIsCloseQuestion"/></div>
          </div>
          <!-- checbox -->
          <!-- <vs-checkbox class="mt-4 ml-0" v-model="dataIsCloseVoting" id="checkbox-voting">{{ $t('Close Voting')}}</vs-checkbox> -->
      </div>
    </vs-prompt>

    <!-- confirm approve -->
    <vs-prompt
      class="approve"
        title="Turn off Moderation"
        :disabled-outside="true"
        accept-icon="check"
        @close="closeApprove()"
        :buttons-hidden="true"
        :active.sync="isModalConfirmApprove">
        <!-- reply section -->
        <div class="p-4 relative">
            {{ $t('This will approve all remaining questions') }}
        </div>
        <div class="button-section">
          <vs-button
              class="px-4 mr-4"
              color="#f3f3f3"
              text-color="black"
              @click="closeApprove()">
              <div class="flex items-center">
                  {{ $t('Cancel') }}
              </div>
          </vs-button>
          <vs-button
              class="px-3 py-3 pr-10 mr-3"
              :disabled="timerCountdown > 0"
              @click="approveAll()">
              <div class="flex items-center">
                  {{ $t('Approve All') }} {{ timerCountdown > 0 ? `(${timerCountdown})` : '' }}
                  <svg class="w-6 ml-2 absolute right-0" style="margin-right: -24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
              </div>
          </vs-button>
        </div>
      </vs-prompt>
    </div>
</template>

<script>
import eventsApi from '@/api/event';
import {
  getAxiosErrorMessage,
} from '../../lib/helper';

export default {
  components: {
  },
  props: {
    isShowSettings: {
      type: Boolean,
      default: () => false,
    },
    listModerationLength: {
      type: Number,
      default: () => 0,
    },
  },
  watch: {
    isShowSettings() {
      if (this.isShowSettings) {
        this.fetchDetails();
        this.isModalActiveLocal = true;
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeSettings');
    },
    isModalConfirmApprove() {
      if (this.isModalConfirmApprove) {
        this.startCountdown();
      } else {
        clearInterval(this.intervalId);
        this.timerCountdown = 5; // Reset to initial value
      }
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      isModalConfirmApprove: false,
      isSending: false,
      dataIsModeration: false,
      dataIsCloseQuestion: false,
      dataIsCloseVoting: true,
      dataIsDisplayQuestionPane: false,
      dataCurrentRole: 'Moderator',
      isFetching: false,
      timerCountdown: 5,
      intervalId: null,
      isStart: false,

      // event settings
      // features data moderation
      eventData: null,
      dataIsAudienceAndQA: true,
      dataIsWithdrawal: true,
      dataIsReplies: true,
      dataMaxLength: 160,
      dataIsDisplayPresentSidebar: true,
      dataIsQRCode: true,
      dataIsDisplayLatestQuestion: true,
      dataIsEnabledBoxBackground: false,
      dataIsFullscreen: false,
      dataRemoveFontStyle: false,
      dataFontSize: 28,

      // features data polls
      dataIsLivePolls: true,
      dataIsVoteCounter: true,
      dataIsPollResult: true,
    };
  },
  computed: {
    isFormValid() {
      return this.dataAnnouncement !== '';
    },
    slug() {
      return this.$route.params.eventSlug;
    },
    sidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/sidebar`;
      return linkCode;
    },
    withoutSidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/quest`;
      return linkCode;
    },
    highlightPresentationURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/highlight`;
      return linkCode;
    },
    eventQRURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/event`;
      return linkCode;
    },
    runnerControlURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/control`;
      return linkCode;
    },
    highlightGrafiasURL() {
      const linkCode = '';
      return linkCode;
    },
  },
  methods: {
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.timerCountdown > 0) {
          this.timerCountdown--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000); // Update every second
    },
    onChangeModeration() {
      if (!this.dataIsModeration && this.listModerationLength > 0) {
        this.isModalConfirmApprove = true;
      }
    },
    approveAll() {
      this.dataIsModeration = false;
      this.isModalConfirmApprove = false;
    },
    closeApprove() {
      this.dataIsModeration = true;
      this.isModalConfirmApprove = false;
    },
    deleteData() {
      this.isModalActiveLocal = false;
      this.$emit('deleteData');
    },
    submitData() {
      this.isSaving = true;
      const notifTitle = this.$t('Event Settings');
      const params = {
        id: this.eventData.id,
      };
      const callback = () => {
        // const message = response.message;
        const message = this.$t('Your changes have been saved.');
        if (!this.dataIsModeration) this.$emit('publishedAll');
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      const eventSettings = {
        question_answer: {
          is_enabled: this.dataIsAudienceAndQA,
          is_moderated: this.dataIsModeration,
          is_withdrawal: this.dataIsWithdrawal,
          is_replies: this.dataIsReplies,
          input_max_length: this.dataMaxLength,
          is_order_by_latest: this.dataIsDisplayLatestQuestion,
          is_closed: this.dataIsCloseQuestion,
          display_sort: this.dataDisplaySort,
          is_only_recent: this.dataIsShowOnlyRecent,
          limit: this.dataLimitQuestionLength,
          is_limit: this.dataIsLimitQuestion,
        },
        sidebar: {
          is_display_presentation_sidebar: this.dataIsDisplayPresentSidebar,
          is_qr_code: this.dataIsQRCode,
          is_display_question_pane: this.dataIsDisplayQuestionPane,
          is_registration_only: this.dataIsRegistrationOnly,
          is_registration_enabled: this.dataIsEnabledRegistrationForm,
          is_email_marketing: this.dataIsEnabledEmailMarketing,
          is_email_update: this.dataIsEnabledEmailUpdate,
          is_enable_extended_field: this.dataIsEnabledExtendedForm,
        },
        polls: {
          is_enabled: this.dataIsLivePolls,
          is_vote_counter_visible: this.dataIsVoteCounter,
          is_show_result: this.dataIsPollResult,
        },
        style: {
          is_remove_font_style: this.dataRemoveFontStyle,
          is_dark_color_event: this.dataIsEnabledDarkFont,
          is_enabled_box_bg: this.dataIsEnabledBoxBackground,
          is_fullscreen: this.dataIsFullscreen,
          font_size: this.dataFontSize || 28,
          is_enabled_countdown: this.dataIsEnabledCountdown,
          is_hide_participant_name: this.dataIsHideParticipantName,
          is_dark_color: this.dataIsDarkColor,
          display_font: this.dataDisplayFont,
          background: this.dataBackground,
          background_type: this.dataBackgroundType,
          event_image: this.dataEventImage,
          is_show_countdown: this.dataIsShowCountdown,
          is_show_location: this.dataIsShowLocation,
          is_show_date_time: this.dataIsShowDateTime,
          is_show_tc: this.dataIsShowTC,
          is_show_notes: this.dataIsShowNotes,
          tc: this.dataTC,
          note: this.dataNotes,
          is_show_notes_placeholder: this.dataIsShowNotesPlaceholder,
          notes_placeholder: this.dataNotesPlaceholder,
          is_show_switch_question: this.dataIsShowSwitchQuestionMenu,
        },
        presentation: {
          question_with_sidebar: this.dataQuestionWithSidebar,
          question_without_sidebar: this.dataQuestionWithoutSidebar,
          highlighted_question: this.dataHighlightedPresentQuestion,
          event_qr: this.dataEventQR,
          display_runner_control: this.dataDisplayRunnerControl,
          is_enabled_custom_html: this.dataIsEnabledCustomHTML,
        },
        grafias: {
          highlighted_question: this.dataHighlightedGrafiasQuestion,
          is_switch: this.dataHighlightGrafiasSwitch,
        },
        email: {
          sender_name: this.dataSenderName,
          sender_mail: null,
          footer_name: this.dataSenderName,
        },
      };
      params.settings = JSON.stringify(eventSettings);
      eventsApi.update(this.eventData.id, params, callback, errorCallback);
    },
    fetchDetails() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        this.isFetching = false;
        document.title = event.name;
        const eventSettings = JSON.parse(response.data.settings);
        this.setEventSettings(eventSettings);
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getBySlug(this.slug, callback, errorCallback);
    },
    setEventSettings(eventSettings) {
      if (eventSettings) {
        // audience qna
        this.dataIsAudienceAndQA = eventSettings.question_answer && eventSettings.question_answer
          .is_enabled ? eventSettings.question_answer.is_enabled : false;
        this.dataIsModeration = eventSettings.question_answer && eventSettings.question_answer
          .is_moderated ? eventSettings.question_answer.is_moderated : false;
        this.dataIsWithdrawal = eventSettings.question_answer && eventSettings.question_answer
          .is_withdrawal ? eventSettings.question_answer.is_withdrawal : false;
        this.dataIsReplies = eventSettings.question_answer && eventSettings.question_answer
          .is_replies ? eventSettings.question_answer.is_replies : false;
        this.dataMaxLength = eventSettings.question_answer && eventSettings.question_answer
          .input_max_length ? eventSettings.question_answer.input_max_length : false;
        this.dataIsDisplayLatestQuestion = eventSettings.question_answer && eventSettings
          .question_answer.is_order_by_latest ? eventSettings.question_answer.is_order_by_latest
          : false;
        this.dataIsCloseQuestion = eventSettings.question_answer && eventSettings.question_answer
          .is_closed ? eventSettings.question_answer.is_closed : false;
        this.dataDisplaySort = eventSettings.question_answer && eventSettings.question_answer.display_sort ? eventSettings.question_answer.display_sort : 'recent';
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsLimitQuestion = eventSettings.question_answer && eventSettings.question_answer.is_limit ? eventSettings.question_answer.is_limit : false;
        this.dataLimitQuestionLength = eventSettings.question_answer && eventSettings.question_answer.limit ? eventSettings.question_answer.limit : 0;
        // sedebar
        this.dataIsQRCode = eventSettings.sidebar && eventSettings.sidebar.is_qr_code
          ? eventSettings.sidebar.is_qr_code : false;
        this.dataIsDisplayPresentSidebar = eventSettings.sidebar && eventSettings.sidebar
          .is_display_presentation_sidebar ? eventSettings.sidebar
            .is_display_presentation_sidebar : false;
        this.dataIsDisplayQuestionPane = eventSettings.sidebar && eventSettings.sidebar
          .is_display_question_pane ? eventSettings.sidebar
            .is_display_question_pane : false;
        this.dataIsRegistrationOnly = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_only ? eventSettings.sidebar
            .is_registration_only : false;
        this.dataIsEnabledRegistrationForm = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_enabled ? eventSettings.sidebar
            .is_registration_enabled : true;
        this.dataIsEnabledEmailUpdate = eventSettings.sidebar && eventSettings.sidebar
          .is_email_update ? eventSettings.sidebar
            .is_email_update : false;
        this.dataIsEnabledEmailMarketing = eventSettings.sidebar && eventSettings.sidebar
          .is_email_marketing ? eventSettings.sidebar
            .is_email_marketing : false;
        this.dataIsEnabledExtendedForm = eventSettings.sidebar && eventSettings.sidebar
          .is_enable_extended_field ? eventSettings.sidebar
            .is_enable_extended_field : false;

        // polls
        this.dataIsLivePolls = eventSettings.polls && eventSettings.polls.is_enabled
          ? eventSettings.polls.is_enabled : false;
        this.dataIsVoteCounter = eventSettings.polls && eventSettings.polls
          .is_vote_counter_visible ? eventSettings.polls.is_vote_counter_visible : false;
        this.dataIsPollResult = eventSettings.polls && eventSettings.polls.is_show_result
          ? eventSettings.polls.is_show_result : false;

        // background
        this.dataIsEnabledBoxBackground = eventSettings.style && eventSettings.style.is_enabled_box_bg ? eventSettings.style.is_enabled_box_bg	 : false;
        this.dataIsFullscreen = eventSettings.style && eventSettings.style.is_fullscreen ? eventSettings.style.is_fullscreen	 : false;
        this.dataRemoveFontStyle = eventSettings.style && eventSettings.style.is_remove_font_style ? eventSettings.style.is_remove_font_style	 : false;
        this.dataIsEnabledDarkFont = eventSettings.style && eventSettings.style.is_dark_color_event ? eventSettings.style.is_dark_color_event	 : false;
        this.dataBackgroundType = eventSettings.style && eventSettings.style.background_type ? eventSettings.style.background_type : 'stretch';
        this.dataFontSize = eventSettings.style && eventSettings.style.font_size ? eventSettings.style.font_size : 28;
        this.dataIsEnabledCountdown = eventSettings.style && eventSettings.style.is_enabled_countdown ? eventSettings.style.is_enabled_countdown	 : false;
        this.dataIsHideParticipantName = eventSettings.style && eventSettings.style.is_hide_participant_name ? eventSettings.style.is_hide_participant_name	 : false;
        this.dataIsDarkColor = eventSettings.style && eventSettings.style.is_dark_color ? eventSettings.style.is_dark_color	 : false;
        this.dataDisplayFont = eventSettings.style && eventSettings.style.display_font ? eventSettings.style.display_font	 : 'Roboto';
        this.dataBackground = eventSettings.style && eventSettings.style.background ? eventSettings.style.background	 : null;
        this.dataEventImage = eventSettings.style && eventSettings.style.event_image ? eventSettings.style.event_image	 : null;
        this.dataTC = eventSettings.style && eventSettings.style.tc ? eventSettings.style.tc	: '';
        this.dataIsShowNotes = eventSettings.style && eventSettings.style.is_show_notes ? eventSettings.style.is_show_notes	 : false;
        this.dataNotes = eventSettings.style && eventSettings.style.note ? eventSettings.style.note	 : '';
        this.dataIsShowNotesPlaceholder = eventSettings.style && eventSettings.style.is_show_notes_placeholder ? eventSettings.style.is_show_notes_placeholder	 : false;
        this.dataNotesPlaceholder = eventSettings.style && eventSettings.style.notes_placeholder ? eventSettings.style.notes_placeholder	 : '';

        if (eventSettings && !eventSettings.style) {
          this.dataIsShowCountdown = true;
          this.dataIsShowLocation = true;
          this.dataIsShowDateTime = true;
          this.dataIsShowTC = true;
          this.dataIsShowSwitchQuestionMenu = true;
        } else {
          this.dataIsShowDateTime = eventSettings.style && eventSettings.style.is_show_date_time ? eventSettings.style.is_show_date_time	 : false;
          this.dataIsShowLocation = eventSettings.style && eventSettings.style.is_show_location ? eventSettings.style.is_show_location	 : false;
          this.dataIsShowCountdown = eventSettings.style && eventSettings.style.is_show_countdown ? eventSettings.style.is_show_countdown	 : false;
          this.dataIsShowTC = eventSettings.style && eventSettings.style.is_show_tc ? eventSettings.style.is_show_tc	 : false;
          this.dataIsShowSwitchQuestionMenu = eventSettings.style && eventSettings.style.is_show_switch_question ? eventSettings.style.is_show_switch_question	 : false;
        }

        // presentation & grafias
        this.dataHighlightedGrafiasQuestion = eventSettings.grafias && eventSettings.grafias.highlighted_question ? eventSettings.grafias.highlighted_question : this.highlightGrafiasURL;
        this.dataDisplayRunnerControl = eventSettings.presentation && eventSettings.presentation.display_runner_control ? eventSettings.presentation.display_runner_control	 : this.runnerControlURL;
        this.dataEventQR = eventSettings.presentation && eventSettings.presentation.event_qr ? eventSettings.presentation.event_qr	 : this.eventQRURL;
        this.dataHighlightedPresentQuestion = eventSettings.presentation && eventSettings.presentation.highlighted_question ? eventSettings.presentation.highlighted_question	 : this.highlightPresentationURL;
        this.dataQuestionWithoutSidebar = eventSettings.presentation && eventSettings.presentation.question_without_sidebar ? eventSettings.presentation.question_without_sidebar	 : this.withoutSidebarURL;
        this.dataQuestionWithSidebar = eventSettings.presentation && eventSettings.presentation.question_with_sidebar ? eventSettings.presentation.question_with_sidebar	 : this.sidebarURL;
        this.dataIsEnabledCustomHTML = eventSettings.presentation && eventSettings.presentation.is_enabled_custom_html ? eventSettings.presentation.is_enabled_custom_html	 : false;
        this.dataHighlightGrafiasSwitch = eventSettings.grafias && eventSettings.grafias.is_switch ? eventSettings.grafias.is_switch : this.highlighSwitch;

        // mail
        this.dataSenderName = eventSettings.email && eventSettings.email.sender_name ? eventSettings.email.sender_name : '';

        // must be in the most bottom
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_countdown')) this.dataIsShowCountdown = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_location')) this.dataIsShowLocation = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_date_time')) this.dataIsShowDateTime = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_tc')) this.dataIsShowTC = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_switch_question')) this.dataIsShowSwitchQuestionMenu = true;
      }
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
	.settings-moderation.con-vs-dialog .vs-dialog {
		min-width: 400px!important;
    }

    .settings-section {
        margin-top: 0px;
        width: 98%;
        min-height: 210px;
    }

    .approve.con-vs-dialog .vs-dialog .vs-dialog-text {
        padding: 0!important;
    }
</style>
